<template>
    <div class="fact-container">
        <h2>{{ title }}</h2>

        <dl v-for="(item, key) in fact" :key="`item-${key}`">
            <dt>{{ key }}</dt>
            <dd>
                <div class="title">
                    {{ item.title }}
                </div>
                <div class="note" v-if="item.note">
                    {{ item.note }}
                </div>
                <div class="price" v-if="item.price">
                    <span>Purchase price </span> {{ item.price }} AUD
                </div>

            </dd>
        </dl>

    </div>
</template>

<script>
export default {
    name: "Fact",
    props: {
        fact: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: true,
        }
    }
}
</script>

<style scoped>
.fact-container {
    border: 2px solid black;
    padding: 0;
    border-radius: 5px;
}
.fact-container h2 {
    background: black;
    color: white;
    text-transform: capitalize;
    padding: 0 1em;
}
dl {
    min-width: 15em;
    display: flex;
    padding: 0.25em 1em;
    justify-content: space-between;
    flex: 1;
}
dl:nth-child(odd) {
    background: #f8f7f7;
}
dl:nth-child(4n) {
    border: none;
}
dl dt {
    text-transform: capitalize;
    font-weight: bold;
}

.price {
    font-weight: bold;
    text-align: right;
    font-size: .8em;
    font-style: italic;
}

.price span {
    font-weight: normal;
}
.title {
    text-align: right;
    font-weight: bold;
    font-size: 0.9em;
}

.note {
    font-size: 0.9em;
    padding: 0.25em;
    text-align: right;
}

</style>
