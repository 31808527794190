const jeepData = {
    suspension: {
        lift: {
            title: "2.5-inch AEV DUALSPORT SUSPENSION – XT",
            price: 1500
        },
        shocks: {
            title: "Bilstein 5100",
            price: 500
        },
        steeringDampener: {
            title: "Teraflex 9550",
            price: 180
        },
        controlArms: {
            title: "AEV Geometry Correction Front Control Arm Brackets",
            price: 170,
            note: "Adjusts caster for improved road handling"
        },
        exhaustSpacer: {
            title: "Synergy Exhaust Spacer Kit",
            price: 55
        },
        trackbar: {
            title: "Teraflex JK Front Forged Heavy Duty Adjustable Trackbar",
            price: 326,
            note: "Gave Jeep a more precise and firm steering and road handling"
        },
        swaybar: {
            title: "JKS Quick Disconnects",
            price: 250
        },
        frontSpacers: {
            title: "Teraflex Front Spacer Kit 0.5-inch",
            price: 45,
            note: "To level the front and make the Jeep sit very straight"
        }
    },
    armor: {
        frontBumper: {
            title: "AEV Premium Front Bar",
            price: 1800
        },
        rearBumper: {
            title: "AEV Premium Rear Bar with Water Tanks",
            price: 1500,
            note: "Offers great protection and serves as a water reservoir for camping"
        },
        tyreCarrier: {
            title: "AEV Tyre Carrier",
            price: 1000,
            note: "Allows to carry extra accessories (shovel, fuel tank) and space to mount additional lights. CAn mount up to 37-inch tyre"
        },
        rockSliders: {
            title: "Smittybilt JK SRC Side Armor 4 Door",
            price: 500
        }
    },
    lights: {
        roofRackLights: {
            title: "4x 25 Watt LED Roof Rack Front Lights",
            price: 60
        },
        drivingLights: {
            title: "2x 75W LED lights with Daytime Running Lights Halo",
            price: 350
        },
        fogLights: {
            title: "2x 45W LED Spread Beam Fog Lights",
            price: 100
        },
        tailLights: {
            title: "2x LED (Brake, Bright Reverse Lights and animated Indicators",
            price: 200
        }
    },
    exterior: {
        wheels: {
            title: "5x Mopar/AEV Savegre 17-inch Matte Black",
            price: 2500,
            note: "Perfect back spacing to clear tyres up to 315x70R17 without sticking out of the original fenders"
        },
        snorkel: {
          title: "Wild Boar Offroad Snorkel",
          price: 850,
          note: "Very unique and elegant design"
        },
        roofRack: {
            title: "Stealth Rack - Heavy Duty Low Profile Roof Rack",
            price: 1400,
            note: "No drilling! It's mounted using the hard top mounts in the frame. Can easily be removed without causing damage. Carries up to 200 Kg static load."
        },
        doorStep: {
            title: "Heavy Duty Door Hinge-mounted Side Foot Step",
            price: 55,
            note: "Makes it easy to access the massive roof rack"
        },
        towBar: {
            title: "Mopar Factory Tow Bar with Brake Controller",
            price: 800
        },
        rearDiffCover: {
            title: "Heavy Duty Rear-Differential Diff Cover (Nodular Iron)",
            price: 250
        },
        frontGrille: {
            title: "Wild Boar Angry Grille",
            price: 250
        },
        underBonnetAir: {
            title: "ARB Air Compressor mounted under Bonnet",
            price: 350,
            note: "Easy to access and great to inflate tyres after a trail"
        }
    },
    interior: {
        headUnit: {
            title: "8-inch Android Quad-Core Head Unit with Blue-Tooth and SatNav",
            price: 450
        },
        soundBar: {
            title: "Speakers in Soundbar replaced with high-quality Alpine speakers",
            price: 100
        },
        grabHandles: {
            title: "2x Front and 2x Rear Wild Boar Heavy Duty Steel (Matte Black) Grab Handles",
            price: 120
        },
        rearSeats: {
            title: "Recliner Block Kit",
            price: 50,
            note: "Allows the rear-seat to recline further for a comfier ride for passengers in the rear"
        },
        tailgateTable: {
            title: "Rugged Ridge Tailgate Table",
            price: 200,
            note: "Perfect accessory for camping (cooking etc.)"
        },
        softTop: {
            title: "2x Soft Tops, 1x Original Sport and 1x Jeep Rubicon High Quality Twill",
            price: null
        },
        bikiniTop: {
            title: "Bikini Soft Top for the Front",
            price: 800,
            note: "Perfect for summer, open the front section of your window without having to carry hard top panels in the back"
        },
        brakeController: {
            title: "Generic Brake Controller",
            price: null
        },
        floorMats: {
            title: "Jeep Wrangler Branded Deep Dish Floor Mats front and rear",
            price: 150
        },
        switchPod: {
            title: "Rugged Ridge - JK A-Pillar Switch",
            price: 45,
            note: "Controls the roof rack LED lights"
        },
        chipTuner: {
            title: "SuperChips Flashpaq 3870",
            price: 750,
            note: "Lets you adjust tyre size, gear ratio, has tunes for different octane fuels and offers automatic transmission shift point optimisation."
        }
    }
}

const atAGlance = {
    model: "Jeep Wrangler JK Sport Unlimited",
    year: "MY 2012",
    engine: "3.6L V6 DOHC 24V FFV",
    doors: "4 doors",
    gearbox: "5-Speed Automatic",
    gearing: "3.73 Gear Ratio",
    color: "Black",
    mileage: "160,385 km",
    extras: "Hard Top and Soft Top",
    vin: "1C4HJWKG4CL145526",
}

export { jeepData, atAGlance }
