<template>
  <div id="app">
    <div class="logo-container">
      <img alt="Wrangler Logo" src="./assets/wrangler_logo.png">
    </div>
    <FactSheet/>
  </div>
</template>

<script>
import FactSheet from "./views/FactSheet";
export default {
  name: 'App',
  components: {
    FactSheet
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 0 1em;
}
.logo-container{
  background: #000;
  text-align: center;
}
.logo-container img {
  width: 90%;
}
</style>
