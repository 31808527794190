<template>
    <div class="fact-sheet">
        <div class="title">
            <br />
        </div>
        <Summary />

        <h1>Upgrades List</h1>
        <div class="price-upgrades-total">
            Total original purchase price of included upgrades: <strong><u>{{ priceUpgradesTotal }} AUD</u></strong>
        </div>
        <div class="facts-container">
            <template v-for="(fact, key) in jeepData">
                <Fact :fact="fact" :title="key" :key="`data-${key}`" class="fact" />
            </template>
        </div>

    </div>
</template>

<script>
    import Summary from "../components/Summary";
    import Fact from "../components/Fact"
    import {jeepData} from "@/jeepData"

    export default {
        name: "FactSheet",
        components: {
            Summary,
            Fact
        },
        data() {
            return {
                jeepData: jeepData
            }
        },
        computed: {
            priceUpgradesTotal() {
                let total = 0
                Object.keys(this.jeepData).forEach(item => {
                    Object.keys(this.jeepData[item]).forEach(subItem => {
                        if (this.jeepData[item][subItem].price) {
                            total += this.jeepData[item][subItem].price
                        }
                    })
                })
                return total
            }
        }
    }
</script>

<style scoped>
.title {
    text-align: center;
}
.facts-container {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    margin-top: 2em;
}
.facts-container .fact {
    flex: 1;
    min-width: 330px;
}
.price-upgrades-total {
    border: 2px dashed #000;
    padding: 1em 2em;
}
</style>
